import React from "react"
import '../styles/styles.scss';

import ErrorPage from '../components/ErrorPage/ErrorPage.component';
import Layout from "../components/layout/layout";

export default function Home() {
  return (
    <Layout>
      <ErrorPage />
    </Layout>
  );
}